@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Thin.ttf');
	font-style: normal;
	font-weight: 100;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-ThinItalic.ttf');
	font-style: italic;
	font-weight: 100;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-ExtraLight.ttf');
	font-style: normal;
	font-weight: 200;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-ExtraLightItalic.ttf');
	font-style: italic;
	font-weight: 200;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Light.ttf');
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-LightItalic.ttf');
	font-style: italic;
	font-weight: 300;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Regular.ttf');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Italic.ttf');
	font-style: italic;
	font-weight: 400;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Medium.ttf');
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-MediumItalic.ttf');
	font-style: italic;
	font-weight: 500;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-SemiBold.ttf');
	font-style: normal;
	font-weight: 600;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-SemiBoldItalic.ttf');
	font-style: italic;
	font-weight: 600;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Bold.ttf');
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-BoldItalic.ttf');
	font-style: italic;
	font-weight: 700;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-ExtraBold.ttf');
	font-style: normal;
	font-weight: 800;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-ExtraBoldItalic.ttf');
	font-style: italic;
	font-weight: 800;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-Black.ttf');
	font-style: normal;
	font-weight: 900;
	font-display: swap;
}
@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/Montserrat-BlackItalic.ttf');
	font-style: italic;
	font-weight: 900;
	font-display: swap;
}
